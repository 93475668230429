.home-circulations {
  background-color: #000000;
  padding-bottom: 156px;

  &__title {
    color: #FFFFFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    margin-bottom: 16px;
  }

  &__container {
    display: flex;
    justify-content: center;
    margin-bottom: 96px;
    gap: 16px;
  }

  &__details {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr) );
    grid-gap: 20px;
  }

  &__detail {
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
      margin-bottom: 16px;
    }

    p {
      color: #FFFFFF;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;
      margin-bottom: 4px;
    }

    span {
      color: #7B7B7B;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 30px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .home-circulations {
    padding-bottom: 96px;

    &__details {
      grid-gap: 36px;
    }

    &__container {
      flex-direction: column;
      align-items: center;
    }
  }
}