.circulation {
  border-radius: 8px;
  background: #FFFFFF;
}
.circulation__wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 100px;
}
.circulation__tabs {
  padding: 8px 24px 0 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1215686275);
  display: flex;
  gap: 8px;
  overflow-inline: auto;
  overflow-block: hidden;
}
.circulation__tab {
  position: relative;
  color: #999999;
  padding: 8px 12px;
  font-family: Poppins, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  background: unset;
  border: unset;
  white-space: nowrap;
}
.circulation__tab-active {
  color: #323232;
}
.circulation__tab-active::after {
  position: absolute;
  bottom: -2px;
  left: 0;
  content: "";
  width: 100%;
  height: 3px;
  border-radius: 16px;
  background: red;
}
.circulation__details {
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 56px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1215686275);
}
.circulation__details-item {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.circulation__details-title {
  color: #999999;
  font-family: Poppins, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.circulation__details-description {
  color: #323232;
  font-family: Poppins, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}
.circulation__details-description--blue {
  color: #24A3FF;
}
.circulation__details-description--red {
  color: #FF0009;
}
.circulation__details-button {
  padding: 11px 20px;
  border-radius: 8px;
  border: 1px solid #FF0009;
  background: #FFFFFF;
  color: #FF0009;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: all ease 0.3s;
}
.circulation__details-button:hover {
  background: #FF0009;
  color: #FFFFFF;
}
.circulation__details-blur {
  filter: blur(5px);
}
.circulation__games {
  padding-top: 24px;
}
.circulation__games-header {
  display: grid;
  grid-template-columns: 538px 1fr 1fr 1fr;
  padding-inline: 24px;
}
.circulation__game {
  display: grid;
  padding: 10px 24px;
  grid-template-columns: 538px 1fr 1fr 1fr;
}
.circulation__game-title {
  color: #323232;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 4px;
}
.circulation__game-date {
  color: #323232;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  gap: 16px;
}
.circulation__game:nth-child(2n) {
  background-color: #EAF6FF;
}

.text-red {
  color: #FF0009;
}

.toolbar {
  width: 100%;
  background-color: #FFFFFF;
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: 2px -8px 19px -13px rgba(0, 0, 0, 0.12);
}
.toolbar__inner {
  max-width: 1240px;
  height: 100%;
  margin: auto;
  padding: 12px 20px;
  display: flex;
  justify-content: space-between;
}
.toolbar__quick-selections {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.toolbar__info {
  color: #7E8299;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  display: flex;
  gap: 4px;
}
.toolbar__info svg {
  stroke: #7E8299;
}
.toolbar__selection-button {
  position: relative;
  padding: 6px 12px;
  border-radius: 8px;
  border: 1px solid #FF0009;
  background: rgba(255, 0, 9, 0.1);
  color: #FF0009;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  transition: all ease 0.3s;
  display: flex;
  align-items: center;
  gap: 4px;
}
.toolbar__selection-button:hover {
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.75);
}
.toolbar__selection-button-active {
  background-color: #FF0009;
  color: #FFFFFF;
  padding-left: 30px;
}
.toolbar__button {
  padding: 16px 28px;
  border-radius: 8px;
  background: #FF0009;
  color: #F6F6F6;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  transition: all ease 0.3s;
}
.toolbar__button:hover {
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.75);
}
.toolbar__button-light {
  color: #FF0009;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  background: #FFFFFF;
  transition: all ease 0.3s;
  border: 1px solid transparent;
  border-radius: 8px;
  padding: 16px 28px;
}
.toolbar__button-light:hover {
  border-color: #FF0009;
}

.mobile-option-name {
  display: none;
}

.animated-check {
  position: absolute;
  left: 10px;
  height: 16px;
  width: 16px;
}

.animated-check path {
  fill: none;
  stroke: #FFFFFF;
  stroke-width: 4;
  stroke-dasharray: 23;
  stroke-dashoffset: 23;
  animation: draw 0.3s linear forwards;
  stroke-linecap: round;
  stroke-linejoin: round;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
@media only screen and (max-width: 800px) {
  .circulation__wrapper {
    padding-bottom: unset;
  }
  .circulation__details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
  .circulation__details-button {
    margin: unset;
    justify-self: start;
  }
  .circulation__games-header {
    display: none;
  }
  .circulation__game {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16px;
  }
  .circulation__game-text {
    grid-column: 1/-1;
  }
  .toolbar {
    position: unset;
    background: unset;
    box-shadow: unset;
  }
  .toolbar__inner {
    flex-wrap: wrap;
    gap: 24px;
  }
  .mobile-option-name {
    display: inline;
  }
}
@media only screen and (max-width: 500px) {
  .circulation__details {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .circulation__details-button {
    margin: unset;
    justify-self: start;
  }
  .circulation__games-header {
    display: none;
  }
}

