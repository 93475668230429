@import 'fonts';
@import 'media';

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

* {
  font-family: 'Roboto', serif !important;
  box-sizing: border-box;

  a {
    text-decoration: none;
  }

  button {
    cursor: pointer;
    border: none;
    outline: unset;
  }
}

#root {
  height: 100%;
}

.container {
  max-width: 1240px;
  margin: auto;
  padding: 0 20px;
}

.dropdown-toggle {
  padding: 10px;
  background: var(--dropdown-bg) !important;
  color: var(--dropdown-text) !important;
  box-shadow: unset !important;
  border-radius: 8px;
  border: unset;

  & svg {
    width: 28px;
    height: 28px;
  }
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-menu {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
}

.dropdown-menu__title {
  color: var(--dropdown-text) !important;
}

.error-text {
  color: var(--red);
}

.bg-gray {
  background-color: var(--light-gray);
}

.br-2 {
  border-radius: 0.5rem;
}

.font-4 {
  font-size: 1rem;
}

.fw-500 {
  font-weight: 500;
}

.text-blue {
  color: var(--blue) !important;
}
.result {
  .MuiTableCell-root {
    padding: 10px !important;
  }
}
