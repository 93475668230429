.auth {
  display: flex;
  height: 100%;
}
.auth__img-container {
  position: relative;
  width: 500px;
  flex-shrink: 0;
  background-image: url('/public/media/images/auth-img.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.auth__img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.auth__img-container svg {
  width: 120px;
  z-index: 1;
  position: absolute;
  top: 20px;
  left: 40px;
}
.auth__img-container a {
  position: absolute;
  bottom: 20px;
  left: 40px;
  color: #ffffff;
  font-size: 13px;
  font-weight: 500;
}
.auth__img-container a:hover {
  color: #ffffff;
}
.auth__container {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #f6f6f6;
}
.auth__login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 332px;
  width: 100%;
}
.auth__title {
  color: #323232;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  margin-bottom: 16px;
}
.auth__title-red {
  color: var(--topaz-color) !important;
}
.auth__register {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 400px;
  width: 100%;
}
.auth__text {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.auth__text-gray {
  color: #999999;
}
.auth__logo {
  display: none;
}
.auth__check-register {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 366px;
  width: 100%;
}
.auth__info {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #999;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.auth__info button {
  color: var(--blue);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: unset;
}
.auth__forgot-password {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 380px;
  width: 100%;
}
.auth__forgot-password--inner {
  max-width: 320px;
}

@media only screen and (max-width: 991px) {
  .auth__img-container {
    display: none;
  }
  .auth__container {
    padding-inline: 20px;
    align-items: flex-start;
    padding-top: 20px;
  }
  .auth__title {
    font-size: 24px;
    text-align: center;
  }
  .auth__logo {
    display: flex;
    justify-content: center;
  }
  .auth__logo img {
    width: 100px;
  }
  .auth__submit {
    width: 100%;
  }
  .auth__register-buttons {
    flex-direction: column;
  }
}

.showButton {
  position: relative;
}
.showButton-eye {
  position: absolute;
  top: 4%;
  right: 6px;
  transform: translateY(50%);
}
