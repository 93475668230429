.circulation {
  border-radius: 8px;
  background: #FFFFFF;

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 100px;
  }

  &__tabs {
    padding: 8px 24px 0 24px;
    border-bottom: 1px solid #0000001f;
    display: flex;
    gap: 8px;
    overflow-inline: auto;
    overflow-block: hidden;
  }

  &__tab {
    position: relative;
    color: #999999;
    padding: 8px 12px;
    font-family: Poppins, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    background: unset;
    border: unset;
    white-space: nowrap;

    &-active {
      color: #323232;

      &::after {
        position: absolute;
        bottom: -2px;
        left: 0;
        content: "";
        width: 100%;
        height: 3px;
        border-radius: 16px;
        background: red;
      }
    }
  }

  &__details {
    padding: 24px;
    display: flex;
    flex-wrap: wrap;
    gap: 56px;
    border-bottom: 1px solid #0000001f;

    &-item {
      display: flex;
      flex-direction: column;
      gap: 2px;
    }

    &-title {
      color: #999999;
      font-family: Poppins, serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }

    &-description {
      color: #323232;
      font-family: Poppins, serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 21px;

      &--blue {
        color: #24A3FF;
      }

      &--red {
        color: #FF0009;
      }
    }

    &-button {
      padding: 11px 20px;
      border-radius: 8px;
      border: 1px solid #FF0009;
      background: #FFFFFF;
      color: #FF0009;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      transition: all ease .3s;

      &:hover {
        background: #FF0009;
        color: #FFFFFF;
      }
    }

    &-blur {
      filter: blur(5px);
    }
  }

  &__games {
    padding-top: 24px;

    &-header {
      display: grid;
      grid-template-columns: 538px 1fr 1fr 1fr;
      padding-inline: 24px;
    }
  }

  &__game {
    display: grid;
    padding: 10px 24px;
    grid-template-columns: 538px 1fr 1fr 1fr;

    &-title {
      color: #323232;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      margin-bottom: 4px;
    }

    &-date {
      color: #323232;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      display: flex;
      gap: 16px;
    }

    &:nth-child(2n) {
      background-color: #EAF6FF;
    }
  }
}

.text-red {
  color: #FF0009;
}

.toolbar {
  width: 100%;
  background-color: #FFFFFF;
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: 2px -8px 19px -13px rgba(0,0,0,0.12);

  &__inner {
    max-width: 1240px;
    height: 100%;
    margin: auto;
    padding: 12px 20px;
    display: flex;
    justify-content: space-between;
  }

  &__quick-selections {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__info {
    color: #7E8299;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    display: flex;
    gap: 4px;

    svg {
      stroke: #7E8299;
    }
  }

  &__selection-button {
    position: relative;
    padding: 6px 12px;
    border-radius: 8px;
    border: 1px solid #FF0009;
    background: rgba(255, 0, 9, 0.10);
    color: #FF0009;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    transition: all ease .3s;
    display: flex;
    align-items: center;
    gap: 4px;

    &:hover {
      box-shadow: 0 0 5px 0 rgba(0,0,0,0.75);
    }

    &-active {
      background-color: #FF0009;
      color: #FFFFFF;
      padding-left: 30px;
    }
  }

  &__button {
    padding: 16px 28px;
    border-radius: 8px;
    background: #FF0009;
    color: #F6F6F6;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    transition: all ease .3s;

    &:hover {
      box-shadow: 0 0 5px 0 rgba(0,0,0,0.75);
    }

    &-light {
      color: #FF0009;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      background: #FFFFFF;
      transition: all ease .3s;
      border: 1px solid transparent;
      border-radius: 8px;
      padding: 16px 28px;

      &:hover {
        border-color: #FF0009;
      }
    }
  }
}

.mobile-option-name {
  display: none;
}

.animated-check {
  position: absolute;
  left: 10px;
  height: 16px;
  width: 16px;
}

.animated-check path {
  fill: none;
  stroke: #FFFFFF;
  stroke-width: 4;
  stroke-dasharray: 23;
  stroke-dashoffset: 23;
  animation: draw .3s linear forwards;
  stroke-linecap: round;
  stroke-linejoin: round;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0
  }
}

@media only screen and (max-width: 800px) {
  .circulation {
    &__wrapper {
      padding-bottom: unset;
    }
    &__details {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;

      &-button {
        margin: unset;
        justify-self: start;
      }
    }

    &__games {
      &-header {
        display: none;
      }
    }

    &__game {
      grid-template-columns: 1fr 1fr 1fr;
      gap: 16px;
      &-text {
        grid-column: 1 / -1;
      }
    }
  }

  .toolbar {
    position: unset;
    background: unset;
    box-shadow: unset;

    &__inner {
      flex-wrap: wrap;
      gap: 24px;
    }
  }

  .mobile-option-name {
    display: inline;
  }
}

@media only screen and (max-width: 500px) {
  .circulation {
    &__details {
      display: grid;
      grid-template-columns: 1fr 1fr;

      &-button {
        margin: unset;
        justify-self: start;
      }
    }

    &__games {
      &-header {
        display: none;
      }
    }
  }
}