.accordion {
  border-radius: 12px;
  background-color: #262626;
}
.accordion__item {
  border-bottom: 1px solid #373737;
}
.accordion__item:last-child {
  border: unset;
}
.accordion__text {
  color: #FFFFFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: start;
}
.accordion__button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: unset;
  padding: 16px 16px 8px 16px;
}
.accordion__description {
  padding: 0 16px 16px 16px;
  color: #FFFFFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

