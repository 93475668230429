/*
   Enter the variables here.
   -----
   Example: "Grids"
*/

// Customization Bootstrap vars from "app/libs/bootstrap/scss/_variables.scss"
$grid-gutter-width: 30px;
$grid-columns: 12;
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        exl: 1440px,
);
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
);

$gutter: $grid-gutter-width;


:root {
  --header-bg: #FFFFFF;
  --footer-bg: #FFFFFF;
  --footer-border: #FFFFFF;
  --text-color: #323232;
  --text-gray: #999999;
  --topaz-color: #FF0009;
  --light-gray: #F6F6F6;
  --dropdown-bg: #eaf6ff;
  --dropdown-text: #999999;
  --blue: #24A3FF;
  --red : #FF0009;
}

[mode="dark"] {
  --header-bg: transparent;
  --footer-bg: #000000;
  --footer-border: #373737;
  --text-color: #FFFFFF;
  --topaz-color: #FFFFFF;
  --text-gray: #999999;
  --light-gray: #F6F6F6;
  --dropdown-bg: rgba(36, 163, 255, 0.20);
  --dropdown-text: #999999;
}
