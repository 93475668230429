.accordion {
  border-radius: 12px;
  background-color: #262626;

  &__item {
    border-bottom: 1px solid #373737;

    &:last-child {
      border: unset;
    }
  }

  &__text {
    color: #FFFFFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: start;
  }

  &__button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: unset;
    padding: 16px 16px 8px 16px;
  }

  &__description {
    padding: 0 16px 16px 16px;
    color: #FFFFFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}