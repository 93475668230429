.my-tickets {
  background-color: #F6F6F6;
  padding-block: 24px;
}
.my-tickets__container {
  border-radius: 8px;
  background: #FFFFFF;
}
.my-tickets__title {
  color: #323232;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 24px;
}

