.ticketgame {
  &:nth-child(2n) {
    background-color: #eaf6ff;
  }
}

.prediction {
  display: flex;
  justify-content: center;
}

.divider {
  margin-block: 1rem;
  border: 1px dashed black;
}

.void-ticket__container {
  padding: 12px 24px;
  border: 2px solid #000000;
  border-radius: 8px;
}

@media print {
  .divider {
    margin-block: 4px;
    border: 1.5px dashed black;
  }

  .ticket-text {
    font-size: 12px;
  }

  .ticket-text__container {
    padding: unset;
    line-height: 4px;
  }

  .print-none {
    display: none !important;
  }
}