.multiply__container {
  border-radius: 8px;
  background: #FF0009;
  padding: 4px 4px 4px 8px;
  display: flex;
  align-items: center;
  gap: 12px;
  position: relative;
}
.multiply__title {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #FFFFFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.multiply__title svg {
  stroke: #FFFFFF !important;
}
.multiply__image {
  position: absolute;
  top: 22px;
}
.multiply__select-container {
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.9);
  padding: 0 6px;
}

