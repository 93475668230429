.header {
  display: flex;
  width: 100%;
  height: 84px;
  z-index: 1;
  background: var(--header-bg);

  &__container {
    width: 100%;
    height: 84px;
    display: flex;
    gap: 56px;
    align-items: center;

    &-item:nth-child(1) {
      width: 85px;

      img {
        width: 100%;
      }
    }

    &-item:nth-child(2) {
      width: 60px;

      a {
        height: 100%;
        display: block;
        text-decoration: unset;

        span {
          display: block;
          font-weight: 700;
          font-size: 36px;
          line-height: 42px;
          color: #ff0009;
        }
      }
    }

    &-item:nth-child(3) {
      .user-container {
        position: relative;
        transform: translateY(0%);
        transition-delay: 0s, 0s, 0.3s;
        padding: 10px;
        height: 48px;
        background: #eaf6ff;
        border-radius: 8px;

        display: flex;
        justify-content: space-between;
        align-items: center;

        svg {
          width: 28px;
          height: 28px;
        }

        span:nth-child(1) {
          display: block;
          width: 28px;
          height: 28px;

          svg {
            width: 28px;
            height: 28px;
          }
        }

        span:nth-child(2) {
          font-family: 'Roboto', serif;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #999999;
        }
      }

      .dropdown-menu {
        display: none;
        position: absolute;
        background: #eaf6ff;
        width: 181px;
        min-height: 50px;
        padding: 16px;
        transform: translateY(-10px);
        border: unset;

        &__title {
          display: none;
        }

        &__item {
          padding-top: 10px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        &__button {
          &:nth-child(2) {
            margin-top: 10px;
          }

          button {
            width: 100%;
            background: #ffffff;
            color: red;
            border-radius: 8px;
            padding: 5px 10px;
            border: 1px solid red;
            white-space: nowrap;
          }

          .button-red {
            background: red;
            color: #ffffff;
          }
        }
      }

      .dropdown-active {
        display: block;
      }
    }
  }

  &__mobile-menu {
    background: unset;
    display: none;

    svg {
      stroke: var(--text-color);
    }
  }

  &__logo {
    width: 100px;
    display: block;

    img {
      width: 100%;
    }
  }

  &__list {
    display: flex;
    gap: 20px;

    &-item {
      padding: 8px;
      color: var(--text-color);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;

      &:hover {
        color: var(--text-color);
      }

      &--active {
        border-bottom: 2px solid #FF0009;
      }
    }
  }

  &__buttons {
    display: flex;
    gap: 12px;
    margin-left: auto;
  }

  &__button {
    padding: 10px 28px;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    border: 1px solid;
    border-radius: 8px;
    color: var(--text-color);

    &:hover {
      color: var(--text-color);
    }

    &--outline {
      background: unset;
      border-color: var(--text-color);
    }

    &--primary {
      background-color: #FF0009;
      border-color: #FF0009;
      color: #FFFFFF;

      &:hover {
        color: #FFFFFF;
      }
    }
  }
}

@media only screen and (max-width: 1199px) {
  .header {
    &__list {
      display: none;
    }

    &__mobile-menu {
      display: block;
    }

    &__container {
      gap: 12px;
    }
  }
}

@media only screen and (max-width: 992px) {
  .header {
    .header-container {
      &-item:nth-child(1) {
        width: 71px;
      }

      &-item:nth-child(2) {
        width: 40px;

        a {
          span {
            font-size: 24px;
            line-height: 28px;
          }
        }
      }

      &-item:nth-child(3) {
        .user-container {
          width: unset;

          span:nth-child(2) {
            display: none;
          }
        }

        .dropdown-menu {
          transform: translateX(-133px);
          border-radius: 8px;

          &__title {
            display: unset;
            text-align: center;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #999999;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .header {
    &__buttons {
      gap: 8px;
    }

    &__button {
      padding: 8px 10px;
    }
  }
}

@media only screen and (max-width: 374px) {
  .header {
    &__button {
      flex-shrink: 0;
      padding: 4px;
    }
  }
}