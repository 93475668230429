.footer {
  background-color: var(--footer-bg);
  padding-top: 40px;
  border-top: 1px solid var(--footer-border);

  &__container {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 175px 75px 172px 456px;
    padding-bottom: 48px !important;
  }

  &__logo {
    display: flex;
    align-items: flex-start;

    svg {
      flex-shrink: 0;

      &:first-child {
        width: 100px;
        fill: var(--topaz-color);
      }

      &:last-child {
        width: 70px;
      }
    }
  }

  &__links {
    ul {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }

  &__text {
    color: var(--text-color);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 8px;

    &--gray {
      color: var(--text-gray);
      font-size: 14px;
      line-height: 22px;

      &:hover {
        color: var(--text-color);
      }
    }
  }

  &__contact {
    ul {
      display: flex;
      flex-direction: column;
      gap: 16px;

      li {
        display: flex;
        gap: 12px;

        svg {
          fill: var(--text-gray);

          &:hover {
            fill: var(--text-color)
          }
        }

        a {
          display: flex;
          gap: 12px;

          &:hover {
            svg {
              fill: var(--text-color)
            }
          }

          svg {
            fill: var(--text-gray);

            &:hover {
              fill: var(--text-color)
            }
          }
        }
      }
    }
  }

  &__legal-age {
    &-inner {
      display: flex;
      gap: 12px;
      margin-bottom: 42px;

      p {
        color: var(--text-color);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
      }
    }

    &-bottom {
      display: flex;
      flex-direction: column;
      gap: 4px;

      p {
        color: #999999;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
      }

      &-flex {
        display: flex;
        gap: 32px;
        align-items: center;
      }
    }
  }

  &__bottom {
    padding-block: 8px;

    p {
      color: var(--text-gray);
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      text-align: center;
    }
  }

  &__crocusoft {
    fill: var(--text-color);
  }

  &__usb {
    &-white {
      fill: var(--text-color);
    }

    &-black {
      fill: var(--footer-bg);
    }
  }
}

@media only screen and (max-width: 991px) {
  .footer {
    &__container {
      grid-template-columns: 75px 172px;
      grid-template-areas:
              "logo logo"
              "links contact"
              "legal legal";
    }

    &__logo {
      grid-area: logo;
      margin-bottom: 30px;
    }

    &__links {
      grid-area: links;
    }

    &__contact {
      grid-area: contact;
    }

    &__legal-age {
      grid-area: legal;
      margin-top: 58px;
      &-inner {
        margin-bottom: 24px;
      }
    }
  }
}