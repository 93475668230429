.circulation-tickets {
  border-radius: 8px;
  background-color: #FFFFFF;
}
.circulation-tickets__header {
  display: flex;
  padding-block: 20px;
  align-items: center;
}
.circulation-tickets__header button {
  width: 32px;
  height: 32px;
  border-radius: 6px;
  background: #F0F0F0;
  margin-right: 20px;
}
.circulation-tickets__titles {
  display: flex;
  gap: 96px;
}
.circulation-tickets__title {
  display: flex;
  gap: 4px;
}
.circulation-tickets__title span:first-child {
  color: #999999;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.circulation-tickets__title span:last-child {
  color: #323232;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

@media only screen and (max-width: 767px) {
  .circulation-tickets__titles {
    flex-direction: column;
    gap: 16px;
  }
}

