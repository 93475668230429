.home-description {
  background-color: #000000;
  padding-bottom: 166px;
}
.home-description__container {
  max-width: 1000px;
  margin: auto;
  display: flex;
  gap: 160px;
}
.home-description__left {
  position: relative;
  width: 516px;
  align-self: flex-start;
}
.home-description__left p {
  position: absolute;
  bottom: -30px;
  right: 0;
  color: #FFFFFF;
  font-size: 48px;
  font-style: normal;
  font-weight: 900;
  line-height: 64px;
  max-width: 428px;
}
.home-description__right {
  display: flex;
  flex-direction: column;
  gap: 48px;
  flex-shrink: 0;
}
.home-description__item {
  display: flex;
  gap: 24px;
  align-items: center;
}
.home-description__icon-box {
  border-radius: 8px;
  background: #262626;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
}
.home-description__text {
  color: #FFFFFF;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}

@media only screen and (max-width: 991px) {
  .home-description__container {
    flex-direction: column;
    align-items: center;
    gap: 48px;
  }
  .home-description__left {
    align-self: unset;
    width: 100%;
    max-width: 335px;
  }
  .home-description__left svg {
    width: 120px;
  }
  .home-description__left p {
    font-size: 36px;
    line-height: 52px;
    left: 25px;
    bottom: 25px;
  }
  .home-description__item {
    gap: 16px;
  }
  .home-description__icon-box svg {
    width: 36px;
    height: 36px;
  }
  .home-description__text {
    font-size: 24px;
  }
}

