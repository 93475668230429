.auth {
  display: flex;
  height: 100%;

  &__img-container {
    position: relative;
    width: 500px;
    flex-shrink: 0;
    background-image: url("/public/media/images/auth-img.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    svg {
      width: 120px;
      z-index: 1;
      position: absolute;
      top: 20px;
      left: 40px;
    }

    a {
      position: absolute;
      bottom: 20px;
      left: 40px;
      color: #FFFFFF;
      font-size: 13px;
      font-weight: 500;

      &:hover {
        color: #FFFFFF;
      }
    }
  }

  &__container {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #F6F6F6;
  }

  &__login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 332px;
    width: 100%;
  }

  &__title {
    color: #323232;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
    margin-bottom: 16px;

    &-red {
      color: var(--topaz-color) !important;
    }
  }

  &__register {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 400px;
    width: 100%;
  }

  &__text {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &-gray {
      color: #999999;
    }
  }

  &__logo {
    display: none;
  }

  &__check-register {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 366px;
    width: 100%;
  }

  &__info {
    display: flex;
    align-items: center;
    gap: 4px;

    color: #999;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    button {
      color: var(--blue);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      background: unset;
    }
  }

  &__forgot-password {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 380px;
    width: 100%;

    &--inner {
      max-width: 320px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .auth {
    &__img-container {
      display: none;
    }

    &__container {
      padding-inline: 20px;
      align-items: flex-start;
      padding-top: 20px;
    }

    &__title {
      font-size: 24px;
      text-align: center;
    }

    &__logo {
      display: flex;
      justify-content: center;

      img {
        width: 100px;
      }
    }

    &__submit {
      width: 100%;
    }

    &__register-buttons {
      flex-direction: column;
    }
  }
}


//.home {
//  position: relative;
//  width: 100%;
//  display: flex;
//  margin: 0;
//  font-family: 'Roboto';
//  font-weight: 500;
//
//  .img-container {
//    width: 500px;
//
//    img {
//      display: block;
//      width: 100%;
//      height: 100vh;
//      min-height: 700px;
//    }
//  }
//
//  .auth-container {
//    width: calc(100% - 500px);
//    display: flex;
//    justify-content: center;
//    align-items: center;
//    background: #f6f6f6;
//    .login {
//      width: 331px;
//      min-height: 363px;
//      padding: 0 20px;
//
//      .logo {
//        width: 85px;
//        height: 34px;
//        margin: auto;
//        display: none;
//        img {
//          width: 100%;
//        }
//      }
//
//      .section-1 {
//        width: 100%;
//        height: 47px;
//        display: flex;
//        align-items: center;
//
//        p {
//          white-space: nowrap;
//          span {
//            font-weight: 500;
//            font-size: 40px;
//            line-height: 47px;
//            &:nth-child(1) {
//              color: #ff0009;
//            }
//            &:nth-child(2) {
//              color: #323232;
//            }
//          }
//        }
//      }
//
//      .section-2 {
//        margin-top: 16px;
//        display: flex;
//
//        &-item:nth-child(1) {
//          font-weight: 500;
//          line-height: 19px;
//        }
//
//        &-item:nth-child(2) {
//          margin-left: 10px;
//          font-weight: 600;
//          a {
//            padding: unset;
//            background-color: unset;
//            font-weight: 600;
//            font-size: 16px;
//            line-height: 19px;
//            color: #24a3ff;
//          }
//        }
//      }
//
//      .section-3 {
//        margin-top: 40px;
//
//        &-title {
//          span {
//            font-weight: 500;
//          }
//        }
//
//        input {
//          width: 100%;
//          height: 51px;
//          border: 1px solid #999999;
//          outline: none;
//          box-sizing: border-box;
//          border-radius: 8px;
//          margin-top: 8px;
//          padding: 16px;
//          &:focus {
//            border: 1px solid #ff0009;
//          }
//        }
//
//        p {
//          margin-top: 5px;
//          color: red;
//        }
//
//        .number-error {
//          margin-top: 5px;
//          span {
//            color: red;
//          }
//        }
//      }
//
//      .section-4 {
//        margin-top: 20px;
//
//        &-title {
//          display: flex;
//          justify-content: space-between;
//
//          &-item {
//            span {
//              font-weight: 500;
//            }
//
//            a {
//              cursor: pointer;
//              background: unset;
//              font-weight: 500;
//              font-size: 14px;
//              line-height: 16px;
//              color: #24a3ff;
//            }
//          }
//        }
//
//        &-item {
//          margin-top: 8px;
//
//          .pin-field {
//            width: 100%;
//            swd-pin-field {
//              width: 100%;
//              display: flex;
//              justify-content: space-between;
//            }
//          }
//          .pin-field-error {
//            margin-top: 5px;
//            span {
//              color: red;
//            }
//          }
//          input {
//            padding: 16px;
//            outline: none;
//            -webkit-appearance: textfield;
//            -moz-appearance: textfield;
//            appearance: textfield;
//
//            width: 51px;
//            height: 51px;
//            border: 1px solid #999999;
//            box-sizing: border-box;
//            border-radius: 8px;
//
//            &:focus {
//              border: 1px solid #ff0009;
//            }
//          }
//        }
//      }
//
//      .section-5 {
//        margin-top: 36px;
//        &-item {
//          button {
//            padding: 16px 48px;
//            font-weight: 500;
//            font-size: 16px;
//            line-height: 19px;
//            background: #ff0009;
//            color: #f6f6f6;
//            border-radius: 8px;
//          }
//        }
//      }
//    }
//
//    .register {
//      width: 400px;
//      height: 268px;
//
//      .logo {
//        width: 85px;
//        height: 34px;
//        margin: auto;
//        display: none;
//        img {
//          width: 100%;
//        }
//      }
//
//      .section-1 {
//        span {
//          font-weight: 600;
//          font-size: 40px;
//          line-height: 47px;
//        }
//      }
//
//      .section-2 {
//        margin-top: 16px;
//
//        font-weight: 500;
//        font-size: 16px;
//        line-height: 19px;
//        color: #999999;
//      }
//
//      .section-3 {
//        margin-top: 40px;
//        &-title {
//          span {
//            font-weight: 500;
//            font-size: 14px;
//            line-height: 16px;
//          }
//        }
//
//        &-item {
//          max-width: 320px;
//
//          input {
//            width: 100%;
//            height: 51px;
//            border: 1px solid #999999;
//            outline: none;
//            box-sizing: border-box;
//            border-radius: 8px;
//            margin-top: 8px;
//            padding: 16px;
//            &:focus {
//              border: 1px solid #ff0009;
//            }
//          }
//          p {
//            margin-top: 5px;
//            color: red;
//          }
//
//          .number-error {
//            margin-top: 5px;
//            span {
//              color: red;
//            }
//          }
//        }
//      }
//
//      .section-4 {
//        margin-top: 20px;
//
//        max-width: 320px;
//        display: flex;
//        justify-content: space-between;
//
//        &-item {
//          button {
//            border: 1px solid #ff0009;
//            border-radius: 8px;
//          }
//        }
//
//        &-item:nth-child(1) {
//          button {
//            padding: 16px 61px;
//            background: #ff0009;
//            color: #f6f6f6;
//            font-weight: 500;
//            font-size: 16px;
//            line-height: 19px;
//          }
//        }
//        &-item:nth-child(2) {
//          button {
//            padding: 16px 48px;
//            background: #f6f6f6;
//            color: #ff0009;
//            font-weight: 500;
//            font-size: 16px;
//            line-height: 19px;
//          }
//        }
//      }
//    }
//
//    .check-register {
//      max-width: 400px;
//
//      .logo {
//        width: 85px;
//        height: 34px;
//        margin: auto;
//        display: none;
//        img {
//          width: 100%;
//        }
//      }
//
//      .section-1 {
//        span {
//          font-weight: 600;
//          font-size: 40px;
//          line-height: 47px;
//        }
//      }
//
//      .section-2 {
//        margin-top: 16px;
//
//        font-weight: 500;
//        font-size: 16px;
//        line-height: 19px;
//        color: #999999;
//      }
//
//      .section-3 {
//        margin-top: 16px;
//
//        &-item {
//          color: #999999;
//          font-weight: 400;
//          font-size: 12px;
//          line-height: 14px;
//
//          button {
//            background: unset;
//            color: #24a3ff;
//            border: unset;
//          }
//        }
//
//        &-item:nth-child(1) {
//          input {
//            outline: none;
//            -webkit-appearance: textfield;
//            -moz-appearance: textfield;
//            appearance: textfield;
//            background: #ffffff;
//            border-radius: 8px;
//            width: 320px;
//            height: 51px;
//            border: none;
//            padding: 16px;
//
//            &:focus {
//              border: 1px solid #ff0009;
//            }
//          }
//        }
//
//        &-item:nth-child(2) {
//          margin-top: 11px;
//
//          display: flex;
//          align-items: center;
//
//          span:nth-child(1) {
//            svg {
//              stroke: #999999;
//              font-size: 16px;
//            }
//          }
//
//          span:nth-child(2) {
//            margin-left: 5.67px;
//          }
//          span:nth-child(3) {
//            margin-left: 4px;
//          }
//          .error {
//            color: red;
//
//            svg {
//              stroke: red !important;
//            }
//          }
//        }
//
//        &-item:nth-child(3) {
//          margin-top: 11px;
//          .formik-error {
//            span {
//              color: red;
//              font-size: 16px;
//              font-weight: 500;
//            }
//          }
//        }
//      }
//
//      .section-4 {
//        margin-top: 43px;
//        &-item {
//          &-title {
//            span {
//              font-weight: 500;
//              font-size: 14px;
//              line-height: 16px;
//            }
//          }
//
//          swd-pin-field {
//            width: 100%;
//            display: flex;
//            justify-content: space-between;
//          }
//
//          input {
//            outline: none;
//            -webkit-appearance: textfield;
//            -moz-appearance: textfield;
//            appearance: textfield;
//            padding: 16px;
//            width: 51px;
//            height: 51px;
//            border: 1px solid #999999;
//            border-radius: 8px;
//
//            &:focus {
//              border: 1px solid #ff0009;
//            }
//          }
//        }
//
//        &-item:nth-child(2) {
//          margin-top: 8px;
//
//          max-width: 320px;
//          display: flex;
//          justify-content: space-between;
//        }
//
//        &-item:nth-child(3) {
//          margin-top: 11px;
//
//          .formik-error {
//            span {
//              color: red;
//            }
//          }
//        }
//      }
//
//      .section-5 {
//        margin-top: 20px;
//        &-item {
//          &-title {
//            span {
//              font-weight: 500;
//              font-size: 14px;
//              line-height: 16px;
//            }
//          }
//
//          swd-pin-field {
//            width: 100%;
//            display: flex;
//            justify-content: space-between;
//          }
//
//          input {
//            padding: 16px;
//            width: 51px;
//            height: 51px;
//            border: 1px solid #999999;
//            border-radius: 8px;
//          }
//        }
//
//        &-item:nth-child(2) {
//          margin-top: 8px;
//
//          max-width: 320px;
//          display: flex;
//          justify-content: space-between;
//        }
//
//        &-item:nth-child(3) {
//          margin-top: 11px;
//          .formik-error {
//            span {
//              color: red;
//            }
//          }
//        }
//      }
//
//      .section-6 {
//        margin-top: 36px;
//        button {
//          padding: 16px 45px;
//          font-weight: 500;
//          font-size: 16px;
//          line-height: 19px;
//          color: #f6f6f6;
//          background: #ff0009;
//          border: none;
//          border-radius: 8px;
//        }
//      }
//    }
//
//    .forgot-pass {
//      max-width: 400px;
//      .logo {
//        width: 85px;
//        height: 34px;
//        margin: auto;
//        display: none;
//        img {
//          width: 100%;
//        }
//      }
//
//      .section-1 {
//        width: 100%;
//        h1 {
//          font-weight: 600;
//          font-size: 40px;
//          line-height: 47px;
//        }
//      }
//
//      .section-2 {
//        margin-top: 16px;
//
//        font-weight: 500;
//        font-size: 16px;
//        line-height: 19px;
//        color: #999999;
//      }
//
//      .section-3 {
//        margin-top: 40px;
//        &-title {
//          span {
//            font-weight: 500;
//            font-size: 14px;
//            line-height: 16px;
//          }
//        }
//
//        &-item {
//          max-width: 320px;
//
//          input {
//            width: 100%;
//            height: 51px;
//            border: 1px solid #999999;
//            outline: none;
//            box-sizing: border-box;
//            border-radius: 8px;
//            margin-top: 8px;
//            padding: 16px;
//            &:focus {
//              border: 1px solid #ff0009;
//            }
//          }
//        }
//
//        &-item:nth-child(3) {
//          margin-top: 11px;
//          color: red;
//          .formik-error {
//            margin-bottom: 10px;
//          }
//        }
//      }
//
//      .section-4 {
//        margin-top: 20px;
//
//        max-width: 320px;
//        display: flex;
//        justify-content: space-between;
//
//        &-item {
//          button {
//            border: 1px solid #ff0009;
//            border-radius: 8px;
//          }
//        }
//
//        &-item:nth-child(1) {
//          button {
//            padding: 16px 48px;
//            background: #ff0009;
//            color: #f6f6f6;
//            font-weight: 500;
//            font-size: 16px;
//            line-height: 19px;
//          }
//        }
//        &-item:nth-child(2) {
//          button {
//            padding: 16px 48px;
//            background: #f6f6f6;
//            color: #ff0009;
//            font-weight: 500;
//            font-size: 16px;
//            line-height: 19px;
//          }
//        }
//      }
//    }
//
//    .new-pass {
//      .section-3 {
//        &-item {
//          &:nth-child(3) {
//            span:nth-child(1) {
//              button {
//                background: unset;
//                color: #24a3ff;
//              }
//            }
//          }
//        }
//      }
//      .section-5 {
//        &-item {
//          &:nth-child(2) {
//            swd-pin-field {
//              input {
//                outline: none;
//                &:focus {
//                  border: 1px solid #ff0009;
//                }
//              }
//            }
//          }
//        }
//      }
//
//      .formik-error {
//        span {
//          font-size: 16px;
//          display: block;
//          padding: 16px;
//          color: #ff0009;
//        }
//      }
//    }
//
//    .custom-progress {
//      width: 100%;
//      height: 100%;
//      position: fixed;
//      top: 0;
//      left: 0;
//      background: rgba(0, 0, 0, 0.5);
//    }
//  }
//}
