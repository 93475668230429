.header {
  display: flex;
  width: 100%;
  height: 84px;
  z-index: 1;
  background: var(--header-bg);
}
.header__container {
  width: 100%;
  height: 84px;
  display: flex;
  gap: 56px;
  align-items: center;
}
.header__container-item:nth-child(1) {
  width: 85px;
}
.header__container-item:nth-child(1) img {
  width: 100%;
}
.header__container-item:nth-child(2) {
  width: 60px;
}
.header__container-item:nth-child(2) a {
  height: 100%;
  display: block;
  text-decoration: unset;
}
.header__container-item:nth-child(2) a span {
  display: block;
  font-weight: 700;
  font-size: 36px;
  line-height: 42px;
  color: #ff0009;
}
.header__container-item:nth-child(3) .user-container {
  position: relative;
  transform: translateY(0%);
  transition-delay: 0s, 0s, 0.3s;
  padding: 10px;
  height: 48px;
  background: #eaf6ff;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header__container-item:nth-child(3) .user-container svg {
  width: 28px;
  height: 28px;
}
.header__container-item:nth-child(3) .user-container span:nth-child(1) {
  display: block;
  width: 28px;
  height: 28px;
}
.header__container-item:nth-child(3) .user-container span:nth-child(1) svg {
  width: 28px;
  height: 28px;
}
.header__container-item:nth-child(3) .user-container span:nth-child(2) {
  font-family: "Roboto", serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #999999;
}
.header__container-item:nth-child(3) .dropdown-menu {
  display: none;
  position: absolute;
  background: #eaf6ff;
  width: 181px;
  min-height: 50px;
  padding: 16px;
  transform: translateY(-10px);
  border: unset;
}
.header__container-item:nth-child(3) .dropdown-menu__title {
  display: none;
}
.header__container-item:nth-child(3) .dropdown-menu__item {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.header__container-item:nth-child(3) .dropdown-menu__button:nth-child(2) {
  margin-top: 10px;
}
.header__container-item:nth-child(3) .dropdown-menu__button button {
  width: 100%;
  background: #ffffff;
  color: red;
  border-radius: 8px;
  padding: 5px 10px;
  border: 1px solid red;
  white-space: nowrap;
}
.header__container-item:nth-child(3) .dropdown-menu__button .button-red {
  background: red;
  color: #ffffff;
}
.header__container-item:nth-child(3) .dropdown-active {
  display: block;
}
.header__mobile-menu {
  background: unset;
  display: none;
}
.header__mobile-menu svg {
  stroke: var(--text-color);
}
.header__logo {
  width: 100px;
  display: block;
}
.header__logo img {
  width: 100%;
}
.header__list {
  display: flex;
  gap: 20px;
}
.header__list-item {
  padding: 8px;
  color: var(--text-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.header__list-item:hover {
  color: var(--text-color);
}
.header__list-item--active {
  border-bottom: 2px solid #FF0009;
}
.header__buttons {
  display: flex;
  gap: 12px;
  margin-left: auto;
}
.header__button {
  padding: 10px 28px;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border: 1px solid;
  border-radius: 8px;
  color: var(--text-color);
}
.header__button:hover {
  color: var(--text-color);
}
.header__button--outline {
  background: unset;
  border-color: var(--text-color);
}
.header__button--primary {
  background-color: #FF0009;
  border-color: #FF0009;
  color: #FFFFFF;
}
.header__button--primary:hover {
  color: #FFFFFF;
}

@media only screen and (max-width: 1199px) {
  .header__list {
    display: none;
  }
  .header__mobile-menu {
    display: block;
  }
  .header__container {
    gap: 12px;
  }
}
@media only screen and (max-width: 992px) {
  .header .header-container-item:nth-child(1) {
    width: 71px;
  }
  .header .header-container-item:nth-child(2) {
    width: 40px;
  }
  .header .header-container-item:nth-child(2) a span {
    font-size: 24px;
    line-height: 28px;
  }
  .header .header-container-item:nth-child(3) .user-container {
    width: unset;
  }
  .header .header-container-item:nth-child(3) .user-container span:nth-child(2) {
    display: none;
  }
  .header .header-container-item:nth-child(3) .dropdown-menu {
    transform: translateX(-133px);
    border-radius: 8px;
  }
  .header .header-container-item:nth-child(3) .dropdown-menu__title {
    display: unset;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #999999;
  }
}
@media only screen and (max-width: 575px) {
  .header__buttons {
    gap: 8px;
  }
  .header__button {
    padding: 8px 10px;
  }
}
@media only screen and (max-width: 374px) {
  .header__button {
    flex-shrink: 0;
    padding: 4px;
  }
}

