@import 'globalmixins';

@include font-face(
  'Roboto',
  '/public/fonts/Roboto/Roboto-Black/Roboto-Black',
  900
);
@include font-face(
  'Roboto',
  '/public/fonts/Roboto/Roboto-BlackItalic/Roboto-BlackItalic',
  900,
  italic
);
@include font-face(
  'Roboto',
  '/public/fonts/Roboto/Roboto-Bold/Roboto-Bold',
  bold
);
@include font-face(
  'Roboto',
  '/public/fonts/Roboto/Roboto-BoldItalic/Roboto-BoldItalic',
  bold,
  italic
);
@include font-face(
  'Roboto',
  '/public/fonts/Roboto/Roboto-Italic/Roboto-Italic',
  normal,
  italic
);
@include font-face(
  'Roboto',
  '/public/fonts/Roboto/Roboto-Light/Roboto-Light',
  300
);
@include font-face(
  'Roboto',
  '/public/fonts/Roboto/Roboto-LightItalic/Roboto-LightItalic',
  300,
  italic
);
@include font-face(
  'Roboto',
  '/public/fonts/Roboto/Roboto-Medium/Roboto-Medium',
  500
);
@include font-face(
  'Roboto',
  '/public/fonts/Roboto/Roboto-MediumItalic/Roboto-MediumItalic',
  500,
  italic
);
@include font-face(
  'Roboto',
  '/public/fonts/Roboto/Roboto-Regular/Roboto-Regular',
  normal
);
@include font-face(
  'Roboto',
  '/public/fonts/Roboto/Roboto-Thin/Roboto-Thin',
  100
);
@include font-face(
  'Roboto',
  '/public/fonts/Roboto/Roboto-ThinItalic/Roboto-ThinItalic',
  100,
  italic
);
@include font-face(
                'Freshman',
                '/public/fonts/Freshman/Freshman',
                400,
                normal
);

