.react-date-picker {
  &__wrapper {
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #999999;
    background: #FFFFFF;
  }
  &__inputGroup {
    &__input:invalid {
      background: unset;
    }
  }
}