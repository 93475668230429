.custom-progress {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
}

.pin-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}/*# sourceMappingURL=style.css.map */