.react-date-picker__wrapper {
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #999999;
  background: #FFFFFF;
}
.react-date-picker__inputGroup__input:invalid {
  background: unset;
}

