.multiply {
  &__container {
    border-radius: 8px;
    background: #FF0009;
    padding: 4px 4px 4px 8px;
    display: flex;
    align-items: center;
    gap: 12px;
    position: relative;
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #FFFFFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    svg {
      stroke: #FFFFFF !important;
    }
  }

  &__image {
    position: absolute;
    top: 22px;
  }

  &__select {
    &-container {
      border-radius: 6px;
      background: rgba(255, 255, 255, 0.90);
      padding: 0 6px;
    }
  }
}