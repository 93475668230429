.checkbox {
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.checkbox input {
  margin: unset;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  position: absolute;
  box-sizing: border-box;
  border-radius: 4px;
  width: 20px;
  height: 20px;
}
.checkbox input:checked + span:before {
  border: unset;
  border: 1px solid red;
  background-color: red;
}
.checkbox input:checked + span:after {
  border-color: #ffffff;
}
.checkbox span {
  pointer-events: none;
}
.checkbox span:before {
  content: "";
  display: inline-block;
  border: 1px solid #999999;
  border-radius: 4px;
  width: 20px;
  height: 20px;
}
.checkbox span:after {
  content: "";
  position: absolute;
  top: 3px;
  left: 3px;
  display: block;
  width: 9px;
  height: 5px;
  border: 2px solid transparent;
  border-top: none;
  border-right: none;
  transform: translate(3px, 4px) rotate(-45deg);
}/*# sourceMappingURL=style.css.map */