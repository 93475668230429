.circulations {
  background-color: var(--light-gray);
  width: 100%;
  height: 500px;
}
.circulations__filter {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

