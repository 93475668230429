.button-custom button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.games {
  padding: 16px 0;
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
}

.game {
  text-transform: uppercase;
  padding-block: 4px;
}
.game:nth-child(2n) {
  background-color: #eaf6ff;
}
.gameTitle {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  gap: 40px;
}

.gameDescription {
  text-transform: uppercase;
}

.startDate {
  white-space: nowrap;
}

.price {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  text-transform: uppercase;
  padding: 8px 0;
  border-bottom: 3px double black;
}
.text {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}
