.results {
  background-color: #f6f6f6;
  .results-top {
    background-color: #f6f6f6;
    padding: 128px 0 30px 0;
  }
  .results-bottom {
    background-color: #f6f6f6;
    padding: 0 0 50px 0;
    .table-end {
      text-align: center;
      border-radius: 8px;
      background-color: rgba(255, 255, 255, 1);
      padding: 24px;
      overflow-x: scroll;
      // ::-webkit-scrollbar-track {
      //   height: ;
      // }
      // ::-webkit-scrollbar-thumb {
      //   background: #888;
      // }
      &::-webkit-scrollbar {
        width: 20px;
      }
      h1 {
        font-family: 'Roboto', serif;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        padding-bottom: 20px;
      }
    }
    &-table {
      display: flex;
    }
    table {
      min-width: 413px;
      border-collapse: collapse;
    }
    thead {
      tr {
        border-bottom: 3px solid rgb(170, 170, 170);
        th {
          color: rgba(153, 153, 153, 1);
        }
      }
    }

    th,
    td {
      padding: 10px; /* Add padding to cells */
      text-align: left; /* Align cell content to the left */
    }

    th {
      // background-color: #f2f2f2; /* Add a light gray background to headers */
    }
  }
  .results-center {
    background-color: #f6f6f6;
    padding-bottom: 32px;
    padding-top: 0;

    &__container {
      border-radius: 8px;
      background: #ffffff;
    }

    &__title {
      color: #323232;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 24px;
    }
  }
  .checkBilet {
    text-align: center;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 1);
    padding: 24px;

    h1 {
      font-family: 'Roboto', serif;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      color: rgba(50, 50, 50, 1) !important;
      padding-bottom: 16px;
    }
    form {
      display: flex;
      align-items: center;
      gap: 12px;
      justify-content: center;
      button {
        padding: 10px 20px;
        border-radius: 8px;
        color: white;
        background-color: rgba(255, 0, 9, 1);
      }
      input {
        width: 280px;
        height: 39px;
        padding: 9px;
        border-radius: 8px;
        border: 1px solid rgba(153, 153, 153, 1);
        &::placeholder {
          opacity: 0.5;
        }
      }
    }
  }
}
