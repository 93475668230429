.hero {
  width: 100%;
  background-image: url("/public/media/hero.png");
  background-size: cover;
  background-position: center;
  position: relative;
}
.hero__container {
  height: 720px;
  position: relative;
}
.hero__caption {
  max-width: 630px;
  position: absolute;
  top: 286px;
  left: 20px;
  right: 20px;
}
.hero__title {
  color: var(--white, #FFF);
  font-size: 48px;
  font-weight: 500;
  line-height: 56px;
  margin-bottom: 8px;
}
.hero__description {
  padding: 8px 16px;
  border-radius: 16px;
  background: #FF0009;
  color: #FFFFFF;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  width: fit-content;
  margin-bottom: 48px;
}
.hero__amount {
  color: #FFFFFF;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #FF0009;
  font-size: 96px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  gap: 8px;
  margin-bottom: 24px;
}
.hero__amount span:first-child {
  font-family: Freshman, serif !important;
}
.hero__amount span:last-child {
  color: var(--white, #FFF);
  font-family: Arial, serif;
  font-size: 96px;
  font-style: normal;
  font-weight: 400;
  line-height: 96px;
}
.hero__link {
  padding: 16px 28px;
  border-radius: 8px;
  background-color: #FF0009;
  color: #FFFFFF;
  display: inline-block;
}
.hero__link:hover {
  color: #FFFFFF;
}
.hero__scroll {
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 64px;
  display: flex;
  align-items: center;
}
.hero__scroll-text {
  color: #FFFFFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.hero__gradient {
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 0;
  background: linear-gradient(0deg, #000 -11.65%, rgba(0, 0, 0, 0) 100%);
  pointer-events: none;
}

@media only screen and (max-width: 767px) {
  .hero {
    background-image: url("/public/media/hero-mobile.png");
  }
  .hero__caption {
    top: 152px;
    max-width: 335px;
  }
  .hero__title {
    font-size: 28px;
    line-height: 32px;
    padding-inline: 12px;
  }
  .hero__description {
    padding: 6px 12px;
    font-size: 28px;
    line-height: 32px;
    border-radius: 12px;
    margin-bottom: 32px;
  }
  .hero__amount {
    font-size: 56px;
  }
  .hero__amount span:last-child {
    font-size: 56px;
    line-height: 56px;
  }
  .hero__scroll {
    bottom: 12px;
  }
}

