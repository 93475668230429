.print-ticket {
  max-width: 455px;
  width: 100%;

  background: #FFF;

  &__container {
    position: absolute;
    z-index: -999999;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5)
  }

  &__text {
    &-gray {
      color: #999;
      font-size: 12px;
      font-weight: 400;
    }
    &-blue {
      color: #24A3FF;
      font-size: 14px;
      font-weight: 500;
    }

    &-red {
      color: #FF0009;
      font-size: 14px;
      font-weight: 500;
    }

    &-black {
      color: #323232;
      font-size: 14px;
      font-weight: 500;
    }

    &-sm {
      font-size: 10px;
    }
  }
}

@media print {
  .print-ticket {
    &__container {
      height: auto;
    }
  }
}