.circulation-card {
  border-radius: 12px;
  background: #252525;
  max-width: 440px;
  overflow: hidden;
  width: 100%;
}
.circulation-card__image {
  position: relative;
}
.circulation-card__image img {
  display: block;
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
}
.circulation-card__game-count {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 0 48px 0 16px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  color: #FFFFFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}
.circulation-card__date {
  background: linear-gradient(90deg, rgba(255, 0, 9, 0.5) 0%, rgba(255, 0, 9, 0) 100%);
  padding: 10px 42px;
}
.circulation-card__date span {
  color: #FFFFFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.circulation-card__caption {
  display: flex;
  justify-content: space-between;
  padding: 16px;
}
.circulation-card__informations {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.circulation-card__information {
  display: flex;
  flex-direction: column;
  gap: 2px;
  max-width: 112px;
}
.circulation-card__information span:nth-child(1) {
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}
.circulation-card__information span:nth-child(2) {
  color: #FFFFFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.circulation-card__information-blur {
  filter: blur(5px);
}
.circulation-card__buttons {
  display: flex;
  align-items: flex-end;
}
.circulation-card__link {
  border-radius: 8px;
  background: #FF0009;
  padding: 10px 28px;
  color: #F6F6F6;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.circulation-card__link:hover {
  color: #F6F6F6;
}

