.main {
  display: flex;
  background: #f6f6f6;
  min-height: 10rem;
  position: relative;

  &-container {
    min-height: 100vh;

    .btn-container {
      display: flex;
      padding-top: 20px;
      //overflow: hidden;
      //overflow-x: scroll;
      white-space: nowrap;
      padding-bottom: 10px;

      .active-btn {
        background: #ff0009;
        color: #ffffff;
      }

      a {
        background: #ffffff;
        color: #999999;
        outline: none;
        padding: 11px 16px;
        border: none;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
      }

      a:first-child {
        padding: 11px 16px;

        border: none;
        border-radius: 8px 0 0 8px;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
      }

      a:last-child {
        padding: 11px 16px;
        border: none;
        border-radius: 0 8px 8px 0;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
      }
    }

    .play-list {
      font-family: 'Roboto', serif;
      font-style: normal;
      padding-top: 20px;

      &-container {
        padding: 0;

        .wrapper {
          margin-bottom: 20px;
          background: #ffffff;
          border-radius: 8px;
          min-height: 130px;
          width: 100%;

          display: grid;
          grid-template-columns: 4fr 1fr;
          grid-template-rows: 1fr;

          &-left {
            // background-color: aqua;
            padding: 24px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &-title {
              font-weight: 500;
              font-size: 20px;
              line-height: 23px;
              color: #323232;
            }

            &-items {
              min-height: 43px;
              display: grid;
              grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
              grid-template-rows: 1fr;

              &-item1 {
                // background-color: beige;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                &-title {
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 16px;
                }

                &-value {
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 19px;
                  color: #323232;
                }
              }

              &-item2 {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                // background-color: rgb(196, 196, 63);
                &-title {
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 16px;
                }

                &-value {
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 19px;
                  color: #323232;
                }
              }

              &-item3 {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                // background-color: rgb(82, 82, 42);
                &-title {
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 16px;
                }

                &-value {
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 19px;
                  color: #24a3ff;

                  span:nth-child(2) {
                    margin-left: 16px;
                  }
                }
              }

              &-item4 {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                // background-color: rgb(179, 179, 169);
                &-title {
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 16px;
                }

                &-value {
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 19px;
                  color: #ff0009;

                  span:nth-child(2) {
                    margin-left: 16px;
                  }
                }
              }
            }
          }

          &-right {
            // background-color: beige;
            display: flex;
            justify-content: center;
            align-items: center;

            &-btn-container {
              button {
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;

                &:nth-child(1) {
                  margin-right: 16px;
                  padding: 10px 32px;
                  background: #ff0009;
                  color: #f6f6f6;
                  border-radius: 8px;
                }

                &:nth-child(2) {
                  padding: 10px 25px;
                  background: #ffffff;
                  color: #ff0009;
                  border: 1px solid #ff0009;
                  box-sizing: border-box;
                  border-radius: 8px;
                }
              }
            }
          }
        }

        .custom-progress {
          z-index: 1;
          width: 100%;
          position: fixed;
          top: 0;
          left: 0;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
        }
      }
    }

    .play {
      font-family: 'Roboto', serif;
      font-style: normal;

      &-container {
        padding: 20px 0;

        .wrapper {
          padding: 24px 0;
          background: #ffffff;
          border-radius: 8px;

          &-title {
            padding: 0 24px;

            span {
              font-weight: 500;
              font-size: 20px;
              line-height: 23px;
              color: #323232;
            }
          }

          &-items {
            margin-top: 41px;

            &-title {
              margin-bottom: 13px;
              padding: 0 24px;
              display: grid;
              grid-template-columns: 2fr 1fr 1fr 1fr;

              span:nth-child(2) {
                margin-left: 4px;
                font-weight: 600;
                font-size: 20px;
                line-height: 23px;
                color: #323232;
              }

              span:nth-child(3) {
                margin-left: 4px;
                font-weight: 600;
                font-size: 20px;
                line-height: 23px;
                color: #323232;
              }

              span:nth-child(4) {
                margin-left: 4px;
                font-weight: 600;
                font-size: 20px;
                line-height: 23px;
                color: #323232;
              }

              button {
                width: fit-content;
                display: flex;
                background: none;
                padding: unset;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #24a3ff;

                span:nth-child(2) {
                  display: block;
                  width: 15px;
                  height: 15px;
                  margin-left: 10px;

                  img {
                    width: 100%;
                  }
                }
              }
            }

            &-item {
              display: grid;
              grid-template-columns: 2fr 1fr 1fr 1fr;
              padding: 10px 24px;

              &:nth-child(2n + 1) {
                background: rgba(234, 246, 255, 0.75);
              }

              &-title {
                &-up {
                  margin-bottom: 4px;

                  span {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 16px;
                    color: #323232;
                  }
                }

                &-down {
                  span {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    color: #323232;

                    &:nth-child(2) {
                      margin-left: 16px;
                    }
                  }
                }
              }

              &-input {
                position: relative;
                display: flex;

                h1 {
                  margin-top: 3px;
                  margin-right: 4px;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 16px;
                  color: #323232;
                  display: none;
                }
              }
            }
          }
        }

        .total-amount {
          margin: 20px 0;

          span:nth-child(1) {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #000000;
          }

          span:nth-child(2) {
            margin-left: 4px;
            font-size: 16px;
            line-height: 19px;
            color: red;
          }
        }

        .btn {
          padding: unset;

          button {
            padding: 16px 31px;
            background: #ff0009;
            border-radius: 8px;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #f6f6f6;
          }
        }
      }

      .custom-progress {
        z-index: 1;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
      }
    }

    .my-tickets {
      font-family: 'Roboto', serif;
      font-style: normal;

      &-container {
        margin-top: 20px;
        padding: unset;
        padding-top: 24px;
        background: #ffffff;
        border-radius: 8px;

        .wrapper {
          &-title {
            padding: 0 24px;
            font-weight: 500;
            font-size: 20px;
            line-height: 23px;
            color: #323232;
          }

          &-filter {
            margin-top: 24px;
            max-width: 51.75rem;
            display: flex;
            justify-content: space-between;
            padding: 0 24px;

            &-group {
              display: flex;
              align-items: center;

              label {
                margin-right: 16px;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                color: #323232;
              }
            }
          }

          &-table {
            margin-top: 24px;
          }
        }
      }
    }

    .tickets {
      &-container {
        padding: unset;
        padding-top: 20px;

        .wrapper {
          background: #ffffff;
          border-radius: 8px;

          &-title {
            padding: 24px 24px 40px 24px;
            display: flex;
            justify-content: space-between;

            &-left {
              width: 31.625rem;
              display: flex;
              justify-content: space-between;

              &-item {
                span:nth-child(1) {
                  margin-right: 4px;
                  font-weight: 400;
                  font-size: 20px;
                  line-height: 23px;
                  color: #999999;
                }

                span:nth-child(2) {
                  font-weight: 400;
                  font-size: 20px;
                  line-height: 23px;
                }

                &:nth-child(1) {
                  margin-right: 20px;
                }
              }
            }

            &-right {
              display: flex;
              button {
                background: unset;
                display: flex;

                span {
                  color: #24a3ff;

                  &:nth-child(2) {
                    //margin-left: 8px;
                    display: block;
                    //width: 16px;
                    //height: 16px;

                    img {
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.blur {
  filter: blur(5px);
}

@media only screen and (max-width: 1200px) {
  .main {
    &-container {
      .play-list {
        &-container {
          .wrapper {
            grid-template-columns: 3fr 1fr;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .main {
    &-container {
      .play-list {
        &-container {
          .wrapper {
            grid-template-columns: 2fr 1fr;

            &-left {
              &-items {
                margin-top: 16px;
                grid-template-columns: 1fr 1fr;
                grid-row-gap: 20px;
              }
            }
          }
        }
      }

      .my-tickets {
        &-container {
          .wrapper {
            &-filter {
              &-group {
                flex-direction: column;
                align-items: unset;

                &-custom-select {
                  margin-top: 8px;
                }
              }
            }
          }
        }
      }

      .tickets-inner {
        &-container {
          .wrapper {
            &-title {
              &-left {
                justify-content: unset;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .main {
    &-container {
      .play-list {
        &-container {
          .wrapper {
            grid-template-columns: 1fr;

            &-left {
              padding: 19px 16px;

              &-title {
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
              }

              &-items {
                grid-column-gap: 20px;

                &-item1 {
                  &-title {
                    margin-bottom: 4px;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 14px;
                  }

                  &-value {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 16px;
                  }
                }

                &-item2 {
                  &-title {
                    margin-bottom: 4px;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 14px;
                  }

                  &-value {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 16px;
                  }
                }

                &-item3 {
                  &-title {
                    margin-bottom: 4px;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 14px;
                  }

                  &-value {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 16px;
                  }
                }

                &-item4 {
                  &-title {
                    margin-bottom: 4px;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 14px;
                  }

                  &-value {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 16px;
                  }
                }
              }
            }

            &-right {
              &-btn-container {
                margin-bottom: 16px;

                button:nth-child(1) {
                  padding: 10px 53px;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 19px;
                }

                button:nth-child(2) {
                  padding: 10px 47px;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 19px;
                }
              }
            }
          }
        }
      }

      .play {
        &-container {
          .wrapper {
            &-items {
              &-title {
                span:nth-child(2) {
                  display: none;
                }

                span:nth-child(3) {
                  display: none;
                }

                span:nth-child(4) {
                  display: none;
                }
              }

              &-item {
                grid-template-columns: 1fr 1fr 1fr;
                grid-template-rows: 1fr 1fr;
                grid-row-gap: 16px;
                grid-template-areas:
                  'title title title'
                  'input input input';

                &-title {
                  grid-area: title;
                }

                &-input {
                  h1 {
                    display: inline;
                  }

                  span {
                    &:before {
                      position: absolute;
                    }
                  }
                }
                .checkbox2 {
                  h1 {
                    position: absolute;
                    left: 40%;
                  }
                  input {
                    left: 50%;
                  }
                  span {
                    &:before {
                      left: 50%;
                    }
                    &:after {
                      left: 52%;
                    }
                  }
                }
                .checkbox3 {
                  h1 {
                    position: absolute;
                    left: unset;
                    right: 25px;
                  }
                  input {
                    left: unset;
                    right: 0;
                  }
                  span {
                    &:before {
                      left: unset;
                      right: 0;
                    }
                    &:after {
                      left: unset;
                      right: 9px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .tickets-inner {
        &-container {
          .wrapper {
            &-title {
              flex-direction: column;

              &-left {
                margin-bottom: 16px;
              }
            }
          }
        }
      }

      .tickets {
        &-container {
          .wrapper {
            &-title {
              &-left {
                flex-direction: column;
                &-item:nth-child(1) {
                  margin-right: unset;
                  margin-bottom: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .main {
    &-container {
      .btn-container {
        //overflow-x: scroll;
        white-space: nowrap;
      }
      .play {
        &-container {
          .wrapper {
            &-items {
              &-item {
                .checkbox2 {
                  justify-content: center;
                }

                .checkbox3 {
                  justify-content: flex-end;
                }
              }
            }
          }

          .btn {
            button {
              width: 100%;
            }
          }
        }
      }

      .my-tickets {
        &-container {
          .wrapper {
            &-filter {
              flex-direction: column;

              &-group {
                &-custom-select {
                  .custom-select {
                    width: 100%;
                  }
                }

                &:nth-child(2) {
                  margin-top: 20px;
                }
              }
            }
          }
        }
      }

      .tickets-inner {
        &-container {
          .wrapper {
            &-title {
              &-left {
                width: 100%;
                flex-direction: column;

                &-item {
                  &:nth-child(1) {
                    margin-bottom: 16px;
                  }
                }
              }
            }
          }
        }
      }

      .tickets {
        &-container {
          .wrapper {
            &-title {
              flex-direction: column;
              &-left {
                flex-direction: column;
                width: 100%;
                &-item:nth-child(2) {
                  margin-bottom: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 374px) {
  .main {
    &-container {
      padding: 0 15px !important;

      .play-list {
        &-container {
          .wrapper {
            &-right {
              &-btn-container {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 16px;
                width: 100%;
                padding: 0 16px;

                button:nth-child(1) {
                  padding: 10px 0;
                  margin: unset;
                }

                button:nth-child(2) {
                  padding: 10px 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
