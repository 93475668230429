.circulation-card {
  border-radius: 12px;
  background: #252525;
  max-width: 440px;
  overflow: hidden;
  width: 100%;

  &__image {
    position: relative;
    img {
      display: block;
      width: 100%;
      height: 100%;
      aspect-ratio: 16/9;
      object-fit: cover;
    }
  }

  &__game-count {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 0 48px 0 16px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%);
    color: #FFFFFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
  }

  &__date {
    background: linear-gradient(90deg, rgba(255, 0, 9, 0.50) 0%, rgba(255, 0, 9, 0.00) 100%);
    padding: 10px 42px;

    span {
      color: #FFFFFF;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }

  &__caption {
    display: flex;
    justify-content: space-between;
    padding: 16px;
  }

  &__informations {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__information {
    display: flex;
    flex-direction: column;
    gap: 2px;
    max-width: 112px;

    span:nth-child(1) {
      color: rgba(255, 255, 255, 0.80);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
    }
    span:nth-child(2) {
      color: #FFFFFF;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }

    &-blur {
      filter: blur(5px);
    }
  }

  &__buttons {
    display: flex;
    align-items: flex-end;
  }

  &__link {
    border-radius: 8px;
    background: #FF0009;
    padding: 10px 28px;
    color: #F6F6F6;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

    &:hover {
      color: #F6F6F6;
    }
  }
}