.primary-button {
  padding: 16px 48px;
  background-color: var(--red);
  border: 1px solid var(--red);
  color: #FFFFFF;
  border-radius: 8px;

  &-outline {
    background-color: #FFFFFF;
    color: var(--red);
  }
}