.filter-button {
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #FFFFFF;
  padding: 6px 12px;
  color: #999999;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: all ease .3s;

  svg {
    stroke: #999999;
  }

  &:hover {
    background-color: #999999;
    color: #FFFFFF;

    svg {
      stroke: #FFFFFF;
    }
  }
}