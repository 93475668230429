@import 'variables';

@media only screen and (max-width: 992px) {
  .home {
    //justify-content: center;
    .img-container {
      display: none;
    }

    .auth-container {
      width: 100%;
      height: 100vh;
      padding-top: 64px;
      align-items: unset;
      .login {
        width: 375px;

        .logo {
          display: block;
        }

        .section-1 {
          width: 199px;
          height: 28px;
          margin: auto;
          margin-top: 20px;
          p {
            span {
              font-weight: 700;
              font-size: 24px;
              line-height: 28px;
            }
          }
          form {
            width: 100%;
          }
        }
        .section-2 {
          display: flex;
          justify-content: center;
        }
        .section-5 {
          button {
            width: 100%;
          }
        }
      }

      .register {
        max-width: 335px;
        padding: 0 20px;
        .logo {
          display: block;
        }

        .section-1 {
          width: 100%;
          display: flex;
          justify-content: center;
          span {
            font-weight: 600;
            font-size: 24px;
            line-height: 28px;
          }
        }

        .section-2 {
          width: 100%;
          display: flex;
          justify-content: center;

          span {
            text-align: center;
          }
        }

        .section-4 {
          flex-direction: column;

          &-item {
            button {
              width: 100%;
            }
          }

          &-item:nth-child(2) {
            margin-top: 20px;
          }
        }
      }

      .check-register {
        max-width: 335px;
        padding: 0 20px;
        .logo {
          display: block;
        }

        .section-1 {
          width: 100%;
          display: flex;
          justify-content: center;
          span {
            font-weight: 600;
            font-size: 24px;
            line-height: 28px;
          }
        }

        .section-2 {
          width: 100%;
          display: flex;
          justify-content: center;
          span {
            text-align: center;
          }
        }

        .section-6 {
          button {
            width: 100%;
          }
        }
      }

      .forgot-pass {
        max-width: 335px;
        padding: 0 20px;
        .logo {
          display: block;
        }

        .section-1 {
          h1 {
            font-weight: 600;
            font-size: 24px;
            line-height: 28px;
            text-align: center;

            color: #323232;
          }
        }

        .section-2 {
          width: 100%;
          display: flex;
          justify-content: center;

          span {
            text-align: center;
          }
        }

        .section-3 {
          &-item {
            max-width: unset;

            input {
              width: 100%;
            }
          }
        }

        .section-4 {
          max-width: unset;
          flex-direction: column;

          &-item {
            button {
              width: 100%;
            }
          }

          &-item:nth-child(2) {
            margin-top: 20px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 376px) {
  .home {
    .auth-container {
      > div {
        width: 100%;
        .login {
          width: 100%;
        }
        .register {
          width: 100%;
          margin: auto;
        }
      }
    }
  }
}
