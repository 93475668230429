.faq {
  background-color: #000000;
  padding-bottom: 62px;
}
.faq__title {
  text-align: center;
  color: #FFFFFF;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  margin-bottom: 16px;
}

