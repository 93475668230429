.home-circulations {
  background-color: #000000;
  padding-bottom: 156px;
}
.home-circulations__title {
  color: #FFFFFF;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  margin-bottom: 16px;
}
.home-circulations__container {
  display: flex;
  justify-content: center;
  margin-bottom: 96px;
  gap: 16px;
}
.home-circulations__details {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
}
.home-circulations__detail {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.home-circulations__detail svg {
  margin-bottom: 16px;
}
.home-circulations__detail p {
  color: #FFFFFF;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  margin-bottom: 4px;
}
.home-circulations__detail span {
  color: #7B7B7B;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

@media only screen and (max-width: 991px) {
  .home-circulations {
    padding-bottom: 96px;
  }
  .home-circulations__details {
    grid-gap: 36px;
  }
  .home-circulations__container {
    flex-direction: column;
    align-items: center;
  }
}

