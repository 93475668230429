.bron-update {
  background-color: #f6f6f6;
  padding-block: 24px;
}
.bron-update__container {
  background-color: #FFFFFF;
  border-radius: 8px;
}
.bron-update__title {
  padding: 24px;
  color: #323232;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

