.input {
  padding: 16px;
  border-radius: 8px;
  background: #FFFFFF;
  border: 1px solid transparent;
  outline: unset;
}
.input:focus {
  border-color: var(--red);
}

