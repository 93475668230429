.tooltip-container {
  max-width: 316px;
}

.tooltip-title {
  color: #323232;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.tooltip-description {
  color: #323232;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

