.checkbox {
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;
  input {
    margin: unset;
    appearance: none;
    position: absolute;

    box-sizing: border-box;
    border-radius: 4px;
    width: 20px;
    height: 20px;

    &:checked + span:before {
      border: unset;
      border: 1px solid red;
      background-color: red;
    }

    &:checked + span:after {
      border-color: #ffffff;
    }
  }

  span {
    pointer-events: none;
  }

  span:before {
    content: '';
    display: inline-block;
    border: 1px solid #999999;
    border-radius: 4px;
    width: 20px;
    height: 20px;
  }

  span:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    display: block;
    width: 9px;
    height: 5px;
    border: 2px solid transparent;
    border-top: none;
    border-right: none;
    transform: translate(3px, 4px) rotate(-45deg);
  }
}
