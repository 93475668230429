.phone-input {
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #999999;
}
.phone-input:focus {
  border-color: #FF0009;
  outline: unset;
}

