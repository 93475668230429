.main {
  display: flex;
  background: #f6f6f6;
  min-height: 10rem;
  position: relative;
}
.main-container {
  min-height: 100vh;
}
.main-container .btn-container {
  display: flex;
  padding-top: 20px;
  white-space: nowrap;
  padding-bottom: 10px;
}
.main-container .btn-container .active-btn {
  background: #ff0009;
  color: #ffffff;
}
.main-container .btn-container a {
  background: #ffffff;
  color: #999999;
  outline: none;
  padding: 11px 16px;
  border: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
.main-container .btn-container a:first-child {
  padding: 11px 16px;
  border: none;
  border-radius: 8px 0 0 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
.main-container .btn-container a:last-child {
  padding: 11px 16px;
  border: none;
  border-radius: 0 8px 8px 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
.main-container .play-list {
  font-family: "Roboto", serif;
  font-style: normal;
  padding-top: 20px;
}
.main-container .play-list-container {
  padding: 0;
}
.main-container .play-list-container .wrapper {
  margin-bottom: 20px;
  background: #ffffff;
  border-radius: 8px;
  min-height: 130px;
  width: 100%;
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-template-rows: 1fr;
}
.main-container .play-list-container .wrapper-left {
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.main-container .play-list-container .wrapper-left-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #323232;
}
.main-container .play-list-container .wrapper-left-items {
  min-height: 43px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
}
.main-container .play-list-container .wrapper-left-items-item1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.main-container .play-list-container .wrapper-left-items-item1-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.main-container .play-list-container .wrapper-left-items-item1-value {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #323232;
}
.main-container .play-list-container .wrapper-left-items-item2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.main-container .play-list-container .wrapper-left-items-item2-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.main-container .play-list-container .wrapper-left-items-item2-value {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #323232;
}
.main-container .play-list-container .wrapper-left-items-item3 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.main-container .play-list-container .wrapper-left-items-item3-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.main-container .play-list-container .wrapper-left-items-item3-value {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #24a3ff;
}
.main-container .play-list-container .wrapper-left-items-item3-value span:nth-child(2) {
  margin-left: 16px;
}
.main-container .play-list-container .wrapper-left-items-item4 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.main-container .play-list-container .wrapper-left-items-item4-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.main-container .play-list-container .wrapper-left-items-item4-value {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ff0009;
}
.main-container .play-list-container .wrapper-left-items-item4-value span:nth-child(2) {
  margin-left: 16px;
}
.main-container .play-list-container .wrapper-right {
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-container .play-list-container .wrapper-right-btn-container button {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
.main-container .play-list-container .wrapper-right-btn-container button:nth-child(1) {
  margin-right: 16px;
  padding: 10px 32px;
  background: #ff0009;
  color: #f6f6f6;
  border-radius: 8px;
}
.main-container .play-list-container .wrapper-right-btn-container button:nth-child(2) {
  padding: 10px 25px;
  background: #ffffff;
  color: #ff0009;
  border: 1px solid #ff0009;
  box-sizing: border-box;
  border-radius: 8px;
}
.main-container .play-list-container .custom-progress {
  z-index: 1;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.main-container .play {
  font-family: "Roboto", serif;
  font-style: normal;
}
.main-container .play-container {
  padding: 20px 0;
}
.main-container .play-container .wrapper {
  padding: 24px 0;
  background: #ffffff;
  border-radius: 8px;
}
.main-container .play-container .wrapper-title {
  padding: 0 24px;
}
.main-container .play-container .wrapper-title span {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #323232;
}
.main-container .play-container .wrapper-items {
  margin-top: 41px;
}
.main-container .play-container .wrapper-items-title {
  margin-bottom: 13px;
  padding: 0 24px;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
}
.main-container .play-container .wrapper-items-title span:nth-child(2) {
  margin-left: 4px;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  color: #323232;
}
.main-container .play-container .wrapper-items-title span:nth-child(3) {
  margin-left: 4px;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  color: #323232;
}
.main-container .play-container .wrapper-items-title span:nth-child(4) {
  margin-left: 4px;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  color: #323232;
}
.main-container .play-container .wrapper-items-title button {
  width: fit-content;
  display: flex;
  background: none;
  padding: unset;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #24a3ff;
}
.main-container .play-container .wrapper-items-title button span:nth-child(2) {
  display: block;
  width: 15px;
  height: 15px;
  margin-left: 10px;
}
.main-container .play-container .wrapper-items-title button span:nth-child(2) img {
  width: 100%;
}
.main-container .play-container .wrapper-items-item {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  padding: 10px 24px;
}
.main-container .play-container .wrapper-items-item:nth-child(2n+1) {
  background: rgba(234, 246, 255, 0.75);
}
.main-container .play-container .wrapper-items-item-title-up {
  margin-bottom: 4px;
}
.main-container .play-container .wrapper-items-item-title-up span {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #323232;
}
.main-container .play-container .wrapper-items-item-title-down span {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #323232;
}
.main-container .play-container .wrapper-items-item-title-down span:nth-child(2) {
  margin-left: 16px;
}
.main-container .play-container .wrapper-items-item-input {
  position: relative;
  display: flex;
}
.main-container .play-container .wrapper-items-item-input h1 {
  margin-top: 3px;
  margin-right: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #323232;
  display: none;
}
.main-container .play-container .total-amount {
  margin: 20px 0;
}
.main-container .play-container .total-amount span:nth-child(1) {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.main-container .play-container .total-amount span:nth-child(2) {
  margin-left: 4px;
  font-size: 16px;
  line-height: 19px;
  color: red;
}
.main-container .play-container .btn {
  padding: unset;
}
.main-container .play-container .btn button {
  padding: 16px 31px;
  background: #ff0009;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #f6f6f6;
}
.main-container .play .custom-progress {
  z-index: 1;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.main-container .my-tickets {
  font-family: "Roboto", serif;
  font-style: normal;
}
.main-container .my-tickets-container {
  margin-top: 20px;
  padding: unset;
  padding-top: 24px;
  background: #ffffff;
  border-radius: 8px;
}
.main-container .my-tickets-container .wrapper-title {
  padding: 0 24px;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #323232;
}
.main-container .my-tickets-container .wrapper-filter {
  margin-top: 24px;
  max-width: 51.75rem;
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
}
.main-container .my-tickets-container .wrapper-filter-group {
  display: flex;
  align-items: center;
}
.main-container .my-tickets-container .wrapper-filter-group label {
  margin-right: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #323232;
}
.main-container .my-tickets-container .wrapper-table {
  margin-top: 24px;
}
.main-container .tickets-container {
  padding: unset;
  padding-top: 20px;
}
.main-container .tickets-container .wrapper {
  background: #ffffff;
  border-radius: 8px;
}
.main-container .tickets-container .wrapper-title {
  padding: 24px 24px 40px 24px;
  display: flex;
  justify-content: space-between;
}
.main-container .tickets-container .wrapper-title-left {
  width: 31.625rem;
  display: flex;
  justify-content: space-between;
}
.main-container .tickets-container .wrapper-title-left-item span:nth-child(1) {
  margin-right: 4px;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #999999;
}
.main-container .tickets-container .wrapper-title-left-item span:nth-child(2) {
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
}
.main-container .tickets-container .wrapper-title-left-item:nth-child(1) {
  margin-right: 20px;
}
.main-container .tickets-container .wrapper-title-right {
  display: flex;
}
.main-container .tickets-container .wrapper-title-right button {
  background: unset;
  display: flex;
}
.main-container .tickets-container .wrapper-title-right button span {
  color: #24a3ff;
}
.main-container .tickets-container .wrapper-title-right button span:nth-child(2) {
  display: block;
}
.main-container .tickets-container .wrapper-title-right button span:nth-child(2) img {
  width: 100%;
}

.blur {
  filter: blur(5px);
}

@media only screen and (max-width: 1200px) {
  .main-container .play-list-container .wrapper {
    grid-template-columns: 3fr 1fr;
  }
}
@media only screen and (max-width: 992px) {
  .main-container .play-list-container .wrapper {
    grid-template-columns: 2fr 1fr;
  }
  .main-container .play-list-container .wrapper-left-items {
    margin-top: 16px;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 20px;
  }
  .main-container .my-tickets-container .wrapper-filter-group {
    flex-direction: column;
    align-items: unset;
  }
  .main-container .my-tickets-container .wrapper-filter-group-custom-select {
    margin-top: 8px;
  }
  .main-container .tickets-inner-container .wrapper-title-left {
    justify-content: unset;
  }
}
@media only screen and (max-width: 768px) {
  .main-container .play-list-container .wrapper {
    grid-template-columns: 1fr;
  }
  .main-container .play-list-container .wrapper-left {
    padding: 19px 16px;
  }
  .main-container .play-list-container .wrapper-left-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }
  .main-container .play-list-container .wrapper-left-items {
    grid-column-gap: 20px;
  }
  .main-container .play-list-container .wrapper-left-items-item1-title {
    margin-bottom: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
  .main-container .play-list-container .wrapper-left-items-item1-value {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
  .main-container .play-list-container .wrapper-left-items-item2-title {
    margin-bottom: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
  .main-container .play-list-container .wrapper-left-items-item2-value {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
  .main-container .play-list-container .wrapper-left-items-item3-title {
    margin-bottom: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
  .main-container .play-list-container .wrapper-left-items-item3-value {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
  .main-container .play-list-container .wrapper-left-items-item4-title {
    margin-bottom: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
  .main-container .play-list-container .wrapper-left-items-item4-value {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
  .main-container .play-list-container .wrapper-right-btn-container {
    margin-bottom: 16px;
  }
  .main-container .play-list-container .wrapper-right-btn-container button:nth-child(1) {
    padding: 10px 53px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }
  .main-container .play-list-container .wrapper-right-btn-container button:nth-child(2) {
    padding: 10px 47px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }
  .main-container .play-container .wrapper-items-title span:nth-child(2) {
    display: none;
  }
  .main-container .play-container .wrapper-items-title span:nth-child(3) {
    display: none;
  }
  .main-container .play-container .wrapper-items-title span:nth-child(4) {
    display: none;
  }
  .main-container .play-container .wrapper-items-item {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 16px;
    grid-template-areas: "title title title" "input input input";
  }
  .main-container .play-container .wrapper-items-item-title {
    grid-area: title;
  }
  .main-container .play-container .wrapper-items-item-input h1 {
    display: inline;
  }
  .main-container .play-container .wrapper-items-item-input span:before {
    position: absolute;
  }
  .main-container .play-container .wrapper-items-item .checkbox2 h1 {
    position: absolute;
    left: 40%;
  }
  .main-container .play-container .wrapper-items-item .checkbox2 input {
    left: 50%;
  }
  .main-container .play-container .wrapper-items-item .checkbox2 span:before {
    left: 50%;
  }
  .main-container .play-container .wrapper-items-item .checkbox2 span:after {
    left: 52%;
  }
  .main-container .play-container .wrapper-items-item .checkbox3 h1 {
    position: absolute;
    left: unset;
    right: 25px;
  }
  .main-container .play-container .wrapper-items-item .checkbox3 input {
    left: unset;
    right: 0;
  }
  .main-container .play-container .wrapper-items-item .checkbox3 span:before {
    left: unset;
    right: 0;
  }
  .main-container .play-container .wrapper-items-item .checkbox3 span:after {
    left: unset;
    right: 9px;
  }
  .main-container .tickets-inner-container .wrapper-title {
    flex-direction: column;
  }
  .main-container .tickets-inner-container .wrapper-title-left {
    margin-bottom: 16px;
  }
  .main-container .tickets-container .wrapper-title-left {
    flex-direction: column;
  }
  .main-container .tickets-container .wrapper-title-left-item:nth-child(1) {
    margin-right: unset;
    margin-bottom: 16px;
  }
}
@media only screen and (max-width: 576px) {
  .main-container .btn-container {
    white-space: nowrap;
  }
  .main-container .play-container .wrapper-items-item .checkbox2 {
    justify-content: center;
  }
  .main-container .play-container .wrapper-items-item .checkbox3 {
    justify-content: flex-end;
  }
  .main-container .play-container .btn button {
    width: 100%;
  }
  .main-container .my-tickets-container .wrapper-filter {
    flex-direction: column;
  }
  .main-container .my-tickets-container .wrapper-filter-group-custom-select .custom-select {
    width: 100%;
  }
  .main-container .my-tickets-container .wrapper-filter-group:nth-child(2) {
    margin-top: 20px;
  }
  .main-container .tickets-inner-container .wrapper-title-left {
    width: 100%;
    flex-direction: column;
  }
  .main-container .tickets-inner-container .wrapper-title-left-item:nth-child(1) {
    margin-bottom: 16px;
  }
  .main-container .tickets-container .wrapper-title {
    flex-direction: column;
  }
  .main-container .tickets-container .wrapper-title-left {
    flex-direction: column;
    width: 100%;
  }
  .main-container .tickets-container .wrapper-title-left-item:nth-child(2) {
    margin-bottom: 16px;
  }
}
@media only screen and (max-width: 374px) {
  .main-container {
    padding: 0 15px !important;
  }
  .main-container .play-list-container .wrapper-right-btn-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 16px;
    width: 100%;
    padding: 0 16px;
  }
  .main-container .play-list-container .wrapper-right-btn-container button:nth-child(1) {
    padding: 10px 0;
    margin: unset;
  }
  .main-container .play-list-container .wrapper-right-btn-container button:nth-child(2) {
    padding: 10px 0;
  }
}

