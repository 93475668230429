.custom-select {
  margin-bottom: 42px;
  display: flex;
  align-items: center;
  padding-left: 24px;
  white-space: nowrap;
  &__label {
    margin-right: 16px;
  }
  .css-b62m3t-container {
    width: 100%;
    max-width: 238px;
    .css-1s2u09g-control {
      .css-319lph-ValueContainer {
        padding-left: 16px;
      }
      .css-1hb7zxy-IndicatorsContainer {
        .css-1okebmr-indicatorSeparator {
          display: none;
        }
      }
    }
    .css-1pahdxg-control {
      .css-319lph-ValueContainer {
        padding-left: 16px;
      }
      .css-1hb7zxy-IndicatorsContainer {
        .css-1okebmr-indicatorSeparator {
          display: none !important;
        }
      }
    }
  }
}

.custom-table {
  .MuiPaper-root {
    box-shadow: unset;
    .MuiTableContainer-root {
      .MuiTable-root {
        .MuiTableHead-root {
          position: relative;
          //&::before {
          //  content: '';
          //  width: 94%;
          //  border-bottom: 1px solid #999999;
          //  position: absolute;
          //  top: 25px;
          //  left: 20px;
          //}
          .MuiTableRow-root {
            white-space: nowrap;
            .MuiTableCell-root {
              border: unset;
              color: #999999;

              &:last-child {
                .MuiButtonBase-root {
                  padding-left: 20px;
                }
              }

              &:nth-child(1) {
                padding-left: 24px;
                .MuiButtonBase-root {
                  padding-left: 11px;
                }
              }
            }
          }
        }
        .MuiTableBody-root {
          .MuiTableRow-root {
            .MuiTableCell-root {
              font-weight: 500;
              border: unset;

              &:last-child {
                padding-left: 20px;
              }
            }
          }
        }
      }
    }
  }

  // .css-1mxz8qt-MuiPaper-root {
  //     box-shadow: unset;

  //      .css-10dfkli-MuiTableCell-root:nth-child(1) {
  //          padding-left: 35px;
  //      }
  // }
  .activeHeadCell {
    svg {
      color: #24a3ff !important;
    }
  }
}

.custom-pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 60px;

  ul {
    li {
      display: flex;
      align-items: center;
      .Mui-selected {
        background-color: #ffffff;
        color: #292d32;
        font-weight: bold;
        &:hover {
          background-color: #ff0009;
        }
      }
      .Mui-disabled {
        opacity: unset;
        background: #ffffff !important;
        svg {
          color: #292d32 !important;
        }
      }
      button {
        padding: 8px;
        background: #ffffff;
        font-weight: 400;
        font-size: 16px;
        color: gray;
        margin: unset;
        border-radius: unset;
        &:hover {
          background: #ff0009;
          color: #ffffff;
        }
      }
      &:first-child {
        transition: 300ms;
        transition-timing-function: linear;
        &:hover {
          transform: translateX(-5px);
        }
        margin-right: 8px;
        button {
          background: #ff0009;
          border-radius: 8px;
          padding: unset;
          svg {
            font-size: 18px;
            color: #ffffff;
          }
        }

        .arrow-disabled {
          background: #ffffff;
          cursor: unset;
          svg {
            stroke: #292d32;
            cursor: unset;
          }
        }
        .arrow-active {
          background: #ff0009;
          svg {
            stroke: #ffffff;
          }
        }
      }

      &:nth-child(2) {
        display: flex;
        align-items: center;
        button {
          padding: 8px;
          border-radius: 8px 0 0 8px;
        }
      }

      &:nth-last-child(2) {
        button {
          border-radius: 0 8px 8px 0;
        }
      }

      &:last-child {
        transition: 300ms;
        transition-timing-function: linear;
        &:hover {
          transform: translateX(5px);
        }
        margin-left: 8px;
        button {
          background: #ff0009;
          border-radius: 8px;
          padding: unset;
          svg {
            font-size: 18px;
            color: #ffffff;
          }
        }
        .arrow-disabled {
          background: #ffffff;
          cursor: unset;
          svg {
            stroke: #292d32;
            cursor: unset;
          }
        }
        .arrow-active {
          background: #ff0009;
          svg {
            stroke: #ffffff;
          }
        }
      }
    }
  }
}

.edit-button {
  background: none;
  margin-left: 8px;
  svg {
    color: orange;
  }
}

.barcode {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__text {
    color: #ff0009;
    text-align: center;
  }
}

.dropdown-menu {
  display: none;
  position: absolute;
  background: #eaf6ff;
  width: 181px;
  min-height: 50px;
  padding: 16px;
  transform: translateY(-10px);
  border: unset;

  &__title {
    display: none;
    text-align: center;
  }

  &__item {
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: space-between;
  }

  &__button {
    &:nth-child(2) {
      margin-top: 10px;
    }

    button {
      width: 100%;
      background: #ffffff;
      color: red;
      border-radius: 8px;
      padding: 5px 10px;
      border: 1px solid red;
      white-space: nowrap;
    }

    .button-red {
      background: red;
      color: #ffffff;
    }
  }
}

@media only screen and (max-width: 992px) {
  .custom-select {
    flex-direction: column;
    align-items: flex-start;
    &__label {
      margin-bottom: 8px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .dropdown-number {
    display: none;
  }
  .dropdown-menu__title {
    display: block;
  }
}

@media only screen and (max-width: 576px) {
  .custom-select {
    padding: 0 24px;
    .css-b62m3t-container {
      max-width: unset;
    }
  }
}
